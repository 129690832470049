import * as $ from "jquery";
import {PSO, API, boundsToZoom} from "./_PSO.js";
import moment from "moment";
import "typeahead.js/dist/typeahead.jquery.js";
import GoogleMapsApi from "./GoogleMapsApi";

const haversine = require("haversine");
import {GOOLE_MAP_API_KEY} from "./_variables";
import {whatIsMyBrowser} from "./lib/Browser";
import Deposits from "./Deposits";
import rating_class from "./lib/rating";
import {confirm} from "./confirm";
import {_storage} from "./_storage";
import {_event} from "./_events";
import {RejectionComponent} from "./RejectionComponent";

const Bloodhound = require("typeahead.js/dist/bloodhound.min.js")
moment.locale('' +
    'pl');

const normalizeTime = time => {
    const date = moment(time);
    return date.format('HH:mm');
}
const normalizeWorkdaysMapper = workday => {
    return {
        day: moment().day(workday.day).format('dd').toUpperCase(),
        start: workday.open && normalizeTime(workday.start),
        end: workday.open && normalizeTime(workday.end)
    }
};
const normalizeWorkdaysReducer = (carry, curr) => {
    const lid = carry.length - 1;
    if (lid >= 0 && carry[lid].start === curr.start && carry[lid].end === curr.end) {
        carry[lid].to = curr.day;
    } else {
        carry.push({
            from: curr.day,
            to: curr.day,
            start: curr.start,
            end: curr.end
        });
    }
    return carry;
};
const normalizeWorkdaysHtmlify = workday => `<span class="dash-text-grey d-flex justify-content-between">
    <span>${workday.from}${workday.from === workday.to ? '' : `-${workday.to}`}</span> 
    <span>&nbsp;${workday.start ? `${workday.start} - ${workday.end}` : 'Nieczynne'}</span>
</span>`;
const normalizeWorkdays = workdays => workdays.map(normalizeWorkdaysMapper)
    .reduce(normalizeWorkdaysReducer, [])
    .map(normalizeWorkdaysHtmlify)
    .join(' ');
const QueryParams = location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});
const checkServiceReservation = service => {
    const distance = Services.user_position.lat
        ? haversine({
            latitude: Services.user_position.lat,
            longitude: Services.user_position.lng
        }, {
            latitude: service.latitude,
            longitude: service.longitude
        })
        : false;
    const hasDeposit = (service.code || service.extranetCode) in Services.deposits;
    let warnings = [];
    if (Services.hasGeolocation && distance !== false && distance > 100) {
        warnings.push(`Serwis jest oddalony o ponad 100km od Twojej lokalizacji.`);
    }
    if (!hasDeposit) {
        warnings.push('W tym serwisie nie ma Twojego depozytu.');
    } else {
        const deposit = Services.deposits[(service.code || service.extranetCode)][0];
        if (deposit.workshop && deposit.workshop.coordinates) {
            const match = deposit.workshop.coordinates.match(/^([\d\.]+)[^\d\.]+([\d\.]+)$/)
            if (match) {
                const depositDistance = haversine({
                    latitude: parseFloat(match[1]),
                    longitude: parseFloat(match[2])
                }, {
                    latitude: service.latitude,
                    longitude: service.longitude
                });
                if (depositDistance > 100) {
                    warnings.push(`Serwis jest oddalony o ponad 100km od lokalizacji opon.`);
                }
            }

        }
    }

    return warnings;
}
const displayWarning = (warnings, target) => {
    const $modal = $("#warning-modal");
    $modal.find(".warnings").html(warnings.join('<br>'));
    $modal.find(".btn-primary").attr('href', target);
    $modal.fadeIn();
};
const getGeolocationLink = () => {

    switch (whatIsMyBrowser()) {
        case 'chrome':
            return 'https://support.google.com/chrome/answer/142065';
            break;
        case 'firefox':
            return 'https://support.mozilla.org/pl/kb/czy-firefox-udostepnia-moje-polozenie-stronom';
            break;
        case 'safari':
            return 'https://support.apple.com/pl-pl/HT207092';
            break;
        case 'opera':
            return 'https://help.opera.com/pl/latest/web-preferences/';
            break;
        case 'edge':
            return 'https://answers.microsoft.com/en-us/windows/forum/all/windows-10-microsoft-edge-location-settings/b2737336-438f-497f-8ea8-9563e45f68b3';
            break;
        case 'ie':
            return 'https://support.microsoft.com/pl-pl/help/17479/windows-internet-explorer-11-change-security-privacy-settings';
            brak;
    }
}
/* Display reservation on list */
const reservationOnList = $service => {

    $service.find('.name').addClass('dash-deposit-status text-orange');
    PSO.getReservation().then(reservation => {
        $('#calendar-confirm-modal').removeClass('offline').removeClass('abort');
        $('#add-to-calendar').removeClass('disabled').prop('disabled', false);
        if (!reservation.confirmed) {
            $('#add-to-calendar').addClass('disabled').prop('disabled', true);
            $('#calendar-confirm-modal').addClass('offline');
        }
        $service.find('.book').addClass('d-none');
        $service.find('.details').removeClass('d-none');
        $service.find('.reservation-info').removeClass('d-none');
        const $img = $service.find('.reservation-info').find('img');
        if (!$img.data('src')) {
            $img.data('src', $img.attr('src'));
        }
        $img.attr('src', reservation.confirmed ? $img.data('src') : $img.data('offline'));
        $service.find('.reservation-info-details')
            .css('color', reservation.confirmed ? '#84e407' : '#FFAF12')
            .html(`${moment(reservation.fromDate)
                .format('dddd, D MMMM YYYY [<span>Godzina] H:mm')
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ')} 
                do ${moment(reservation.toDate).format('H:mm')}
                ${reservation.confirmed ? '' : '<br><b>Rezerwacja oczekuje na potwierdzenie</b>'}
                </span>`)
    }).catch(() => {

        $service.find('.reservation-info').addClass('d-none');
    });
}
/* Display reservation on single service list */
const reservationOnSingle = () => {
    PSO.getReservation().then(reservation => {

        Services.reservationCode = reservation.code;
        if (reservation.code == Services.code) {
            $('.with-reservation').removeClass('d-none');
            $('#go-to-workshop').attr('target', '_blank').attr('href', PSO.getNavigationLinkFromLatLng(reservation.latitude, reservation.longitude));
            $("#abort-pinned-button").removeClass('d-none').unbind('click').on('click', function () {
                const $modal = $("#calendar-confirm-modal");
                $modal.removeClass('online').removeClass('offline').addClass('abort');
                $modal.find('#confirm-workshop-name').html(reservation.name);
                $modal.find('#confirm-workshop-street').html(reservation.street);
                $modal.find('#confirm-workshop-city').html(reservation.city);
                $modal.find('#confirm-date').html(moment(reservation.fromDate).format('DD MMMM YYYY [o godz.] H:mm'));
                $modal.find('.confirm-content').hide();
                $modal.find('#abort-reservation')
                    .removeClass('d-none')
                    .unbind('click')
                    .on('click', e => {
                        PSO.preloader.start();
                        PSO.abortReservation(PSO.car.carId)
                            .then(() => {
                                $modal.fadeOut();
                                $('.with-reservation').addClass('d-none');
                                $("#abort-pinned-button").addClass('d-none');
                                $("#book-pinned-button").removeClass('d-none').addClass('line-176'); ;
                                PSO.preloader.stop();
                                // _event.emmit(_event.ON_PAGE_CHANGE, {page: 'services'});
                            })
                            .catch(PSO.preloader.stop)
                    });
                $modal.find('#success').show();
                $modal.fadeIn();
            });
            reservationOnList($('#services-container'));
        }
    }).catch(() => {
        if (Services.code) {
            if (Services.service.id) {
                $("#book-pinned-button").removeClass('d-none').addClass('line-152'); ;
                $("#call-pinned-button").addClass('d-none')
            } else {
                $("#call-pinned-button").removeClass('d-none').addClass('line-152');
                $("#book-pinned-button").addClass('d-none')
            }
        }
    });
}

const rejection = async () => {
    let rejection;
    try {
        rejection = await _storage.getItem('rejection');
    } catch {
        rejection = false;
    }
    let code = window.location.hash.match(/\/services\/code\/([^\/\?]+)/);
    if (!code) {
        return;
    }
    code = code[1];
    if (code && rejection) {
        $('#book-pinned-button').addClass('d-none'); ;
    } else if (code) {
        if (await _storage.getItem('reservation')) {
            $('#book-pinned-button').addClass('d-none'); ;
        } else {
            $('#book-pinned-button').removeClass('d-none'); ;
        }
    }

    // const rejection = await _storage.getItem('rejection');
    // if (rejection) {
    //     const $container = $(`[data-code=${rejection.garage.code}]`);
    //     $("#book-pinned-button").addClass('d-none');
    // } else {
    //     $("#book-pinned-button").removeClass('d-none');
    // }
    return;

    PSO.getRejection().then(rejection => {
        if ((rejection !== false) && (typeof rejection === 'object') && (Object.keys(rejection).indexOf('garage') !== -1)) {
            rejection.dates = rejection.dates.filter(date => {
                const time = moment(date);
                return time.diff(moment(), 'hour') > 2;
            })
            if (rejection.dates.length == 0) {
                return;
            }
            const $container = $(`[data-code=${rejection.garage.code}]`);
            const $rContainer = $container.find('.suggested-dates');
            const $rButtons = $container.find('[data-field="date-buttons"]');
            const $template = $($rButtons.data('template'));
            const $confirm_modal = $('#calendar-confirm-modal');
            $('#calendar-confirm-modal').removeClass('offline').removeClass('abort');
            $("#book-pinned-button").addClass('d-none'); ;
            $container.find('.book').addClass('d-none');
            $container.find('.details').removeClass('d-none')
            $rContainer.removeClass('d-none');
            $rButtons.children().remove();
            rejection.dates.forEach(date => {
                const $item = $template.clone();
                const time = moment(date);
                $item.html(`${time.format('DD MMMM YYYY [godz.] H:mm')}`);
                $item.data('date', date);
                $rButtons.append($item);
            })
            $container.find('.abort-all-suggested-button').unbind('click').click(function (e) {
                e.preventDefault();
                e.stopPropagation();

                confirm("Na pewno chcesz anulować wszystkie terminy?", () => {
                    PSO.preloader.start();
                    _storage.removeItem('rejection');
                    $rContainer.addClass('d-none');
                    $container.find('.book').removeClass('d-none');
                    $container.find('.details').addClass('d-none');
                    API.asyncPostCall('delete-event-rejection')
                        .then(() => PSO.preloader.stop())
                        .catch(() => PSO.preloader.stop())
                }, 'btn-danger');
            })
            $rButtons.find('button').unbind('click').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                const date = $(this).data('date');

                confirm("Potwierdzasz wybór terminu:<br>" + $(this).text() + "?", () => {

                    PSO.preloader.start();
                    API.asyncPostCall('choose-new-date', {
                        token: rejection.token,
                        date: date
                    })
                        .then(() => {
                            try {
                                PSO.preloader.stop();
                                if (Services.service) {
                                    $confirm_modal.find('#confirm-workshop-name').html(Services.service.name);
                                    $confirm_modal.find('#confirm-workshop-street').html(Services.service.street);
                                    $confirm_modal.find('#confirm-workshop-city').html(Services.service.city);
                                } else {
                                    $confirm_modal.find('#confirm-workshop-name').html($container.find('.name').text());
                                    $confirm_modal.find('#confirm-workshop-street').html($container.find('.street').text());
                                    $confirm_modal.find('#confirm-workshop-city').html($container.find('.city').text());
                                }
                                $confirm_modal.find('#confirm-date').html(moment(date).format('DD MMMM YYYY [o godz.] H:mm'));
                                $confirm_modal.fadeIn();
                                ;
                                $confirm_modal.find('.confirm-content').hide();
                                $confirm_modal.find('#success').show();
                                $confirm_modal.find('.modal-close').unbind('click').click(function (e) {
                                    window.location.reload();
                                });
                                _event.emmit(_event.ON_EVENT_CHANGE);
                                PSO.getReservation();
                            } catch (e) {
                                console.error(e)
                            }
                        })
                        .catch(() => {
                            PSO.preloader.stop();
                            $confirm_modal.fadeIn();
                            $confirm_modal.find('.confirm-content').hide();
                            $confirm_modal.find('#error-unknown').show();
                        })
                }, 'btn-primary');
            });
        }
    });
}

_event.addListener([_event.ON_MERCURE_REJECT, _event.ON_MERCURE_DELETE], e => {
    Services.service && (Services.service.reservation = false);
    Services.reservationCode = null;
    $('.reservation-info:visible, .with-reservation:visible').each(function () {
        $(this).addClass('d-none');
        $(this).parent().find('.book').removeClass('d-none');
        $(this).parent().find('.details').addClass('d-none');
    })
    if ($('#abort-pinned-button').is(':visible')) {
        $('#abort-pinned-button').addClass('d-none');
        $('#book-pinned-button').removeClass('d-none'); ;
        $('#book-pinned-button').unbind('click').on('click', () => {
                let warnings = checkServiceReservation(Services.service);
                if (warnings.length) {
                    return displayWarning(warnings, returnUrl)
                }
                window.location.href = '/#calendar' + Services.service_id
            }
        );
    }
})
_event.addListener(_event.ON_MERCURE_UPDATE, e => {
    console.info('[EVENT]', arguments);
    $('.reservation-info:visible').each(function () {
        reservationOnList($(this).parent());
    })
});
_event.addListener(_event.ON_MERCURE_REJECT, rejection);
document.addEventListener('services-loaded', rejection);
_storage.onItemChange('reservation', async reservation => {
    $('.reservation-info').addClass('d-none');
    reservation && reservationOnList($(`[data-code=${reservation.code}]`));

    let code = window.location.hash.match(/\/services\/code\/([^\/\?]+)/);
    if (!code) {
        return;
    }
    code = code[1];
    $('#abort-pinned-button').addClass('d-none').addClass('line-346');
    if (code && reservation) {
        $('#abort-pinned-button').removeClass('d-none').addClass('line-348');
    }
});
_storage.onItemChange('rejection', async rejection => {
    let code = window.location.hash.match(/\/services\/code\/([^\/\?]+)/);
    if (!code) {
        return;
    }
    code = code[1];
    if (code && rejection) {
        $('#book-pinned-button').addClass('d-none').addClass('line-358'); ;
    } else if (code) {
        if (await _storage.getItem('reservation')) {
                $('#book-pinned-button').addClass('d-none').addClass('line-361'); ;
        } else {
            $('#book-pinned-button').removeClass('d-none').addClass('line-363'); ;
        }
    }
})

const Services = {};
Services.map = undefined;
Services.map_center = {lat: 52.237, lng: 21.017};
Services.user_position = {lat: undefined, lng: undefined};
Services.use_map_center = false;
Services.typeahead_source = [];
Services.search_to_code = [];
Services.search_results = [];
Services.markers = [];
Services.$search = undefined;
Services.$container = undefined;
Services.$service_template = undefined;
Services.$service_rating_template = undefined;
Services.service_id = null;
Services.code = null;
Services.service = null;
Services.search_number = 0;
Services.mode = null; // null / 'relocation' / 'book'
Services.userMarker = null;
Services.reservationCode = null;
Services.deposits = {};
Services.hasGeolocation = false;

Services.initGeolocation = () => {

    $('#enable-geolocation').attr('href', getGeolocationLink());
}
Services.initMap = function () {
    Services.map = new google.maps.Map(document.getElementById('map'), {
        center: Services.map_center,
        zoom: 11,
        disableDefaultUI: true,
        zoomControl: $(window).width() >= 768
    });
    Services.getUserPosition();
    PSO.grayScaleMap(Services.map);
};
Services.userPositionErrorCallback = () => {

    Services.user_position = {lat: 52.237, lng: 21.017};
    const onFoundCords = coords => {
        Services.user_position = coords;
        Services.map.setCenter(Services.user_position);
        Services.search();
    }
    let deposits = Services.deposits;
    // punkt startowy pokazujemy jako lokalizację zdeponowania opon / docelowy punkt zdeponowania opon
    //  (jeśli jest w trakcie relokacji) - jeśli nie mamy depozytu:
    // lokalizacja serwisu, w którym mamy umówioną wizytę, jeśli nie ma umówionej:
    // pokazujemy środek PL - tylko w tym wypadku fjnie byłoby dodać większe oddalenie
    //  - żeby było widać większość kraju
    if (deposits && deposits.length && deposits[0].workshop) {
        PSO.workshopGetCoords(PSO.workshops[deposits[0].workshop.code]).then(onFoundCords).catch(console.error)
        return;
    }
    PSO.getReservation()
        .then(reservation => PSO.workshopGetCoords(PSO.workshops[reservation.code]).then(onFoundCords).catch(console.error))
        .catch(() => {
            Services.search();
            Services.map.setCenter(Services.user_position);
            Services.map.setZoom(7);
        })


    $("#services-container, .main-container").addClass('no-position');
    Services.hasGeolocation = false;
    // Services.search();
    // Services.map.setZoom(6);

};
Services.loadUserMarker = function () {
    if (Services.userMarker || !Services.service_id) {
        return false;
    }
    if (typeof google !== 'undefined') {

        if (!Services.markers[0]) {
            return setTimeout(Services.loadUserMarker, 500);
        }

        Services.userMarker = new google.maps.Marker({
            position: Services.user_position,
            title: 'Twoja lokalizacja',
            animation: google.maps.Animation.DROP,
            map: Services.map,
            icon: '/build/images/user.png'
        });


        let marker = Services.markers[0];
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(marker.getPosition());
        if (Services.userMarker) {
            $("#goto-my-location").removeClass('d-none')
                .attr('target', '_blank')
                .attr('href', PSO.getNavigationLinkFromLatLng(marker.getPosition().lat(), marker.getPosition().lng()))
            bounds.extend(Services.userMarker.getPosition());
            Services.map.fitBounds(bounds);
            navigator.geolocation.watchPosition(position => {
                Services.user_position = {lat: position.coords.latitude, lng: position.coords.longitude};

                Services.userMarker.setPosition(Services.user_position);
            })
        }
        ;

    } else {
        setTimeout(Services.loadUserMarker, 1000);
    }
}
Services.getUserPosition = function () {

    if (Services.code !== null) {
        Services.service = PSO.workshops[Services.code];
        Services.$search.typeahead('val', Services.service.search_phrase);
        Services.$search.trigger('typeahead:change');
        navigator.geolocation.getCurrentPosition(function (position) {
            Services.user_position = {lat: position.coords.latitude, lng: position.coords.longitude};

            Services.loadUserMarker();
            $("#services-container, .main-container").removeClass('no-position');
            Services.hasGeolocation = true;
        }, function () {
            $("#services-container, .main-container").addClass('no-position');
            Services.hasGeolocation = false;
        }, {
            timeout: 2000
        });
    } else {

        navigator.geolocation.getCurrentPosition(function (position) {

            Services.user_position = {lat: position.coords.latitude, lng: position.coords.longitude};
            Services.loadUserMarker();
            Services.map_center = Services.user_position;
            if (Services.map) {
                Services.map.setCenter(Services.user_position);
                Services.map.setZoom(11);
            }
            Services.search();
            $("#services-container, .main-container").removeClass('no-position');
            Services.hasGeolocation = true;
        }, Services.userPositionErrorCallback, {
            timeout: 2000
        });
    }
};
Services.getWorkshopById = function (id, useWorkshopId = false) {
    useWorkshopId = useWorkshopId || window.location.hash.indexOf('workshop-id') > -1;
    for (let i in PSO.workshops) {

        if (useWorkshopId && typeof PSO.workshops[i].workshopId !== 'undefined' && parseInt(PSO.workshops[i].workshopId) === id) {
            return PSO.workshops[i];
        }

        if (!useWorkshopId && typeof PSO.workshops[i].id !== 'undefined' && PSO.workshops[i].id === id) {
            return PSO.workshops[i];
        }

        if (typeof PSO.workshops[i].extranetCode !== 'undefined' && PSO.workshops[i].extranetCode === id) {
            return PSO.workshops[i];
        }

    }
};
Services.findServiceIdByCode = function (code) {
    for (let i in PSO.workshops) {
        if (PSO.workshops[i].extranetCode == code) {
            return PSO.workshops[i].id;
        }
        if (PSO.workshops[i].code == code) {
            return PSO.workshops[i].workshopId;
        }
    }
    return code;
};
Services.processWorkshops = function () {
    Services.typeahead_source = [];
    Services.search_to_code = [];
    for (let i in PSO.workshops) {
        let text = typeof PSO.workshops[i].id === 'undefined' ?
            PSO.workshops[i].nameShort + ', ' + PSO.workshops[i].street + ', ' + PSO.workshops[i].city :
            PSO.workshops[i].name + ', ' + PSO.workshops[i].street + ', ' + PSO.workshops[i].city;
        PSO.workshops[i].search_phrase = text;
        Services.typeahead_source.push(text);
        Services.search_to_code[text] = PSO.workshops[i].extranetCode || PSO.workshops[i].code;
    }
};
Services.getWorkshops = async function (callback, force) {
    if (typeof force === 'undefined') force = false;
    if (force || await _storage.getItem('workshops') === null) {
        await _storage.removeItem('workshops')
    }
    PSO.workshops = await _storage.getItem('workshops');
    Services.processWorkshops();
    if (typeof callback !== 'undefined') callback();
    return;

    API.call('GET', /*'workshops'*/ 'get-workshops', {}, function (response) {

        // let ex_workshops = [];
        // //let ids = [];
        // for (let i in response.message) {
        //     //ids.push(response.message[i].code);
        //     ex_workshops = ex_workshops.concat(response.message[i]);
        // }
        // //
        // API.call('WO', 'GET', 'get_workshops', {}, function(response) {
        //     let wo_workshops = response;
        //     for (let i in wo_workshops) {
        //         for (let j in ex_workshops) {
        //             if (parseInt(wo_workshops[i].extranetCode) === ex_workshops[j].workshopId) {
        //                 ex_workshops.splice(j, 1);
        //                 j--;
        //             }
        //         }
        //     }
        PSO.workshops = response /* wo_workshops.concat(ex_workshops) */;
        Services.processWorkshops();
        _storage.setItem('workshops', PSO.workshops);
        if (typeof callback !== 'undefined') callback();
        // });
    });
};
Services.initSearch = function () {



    if (!Services.code) {

        // const autocomplete = new google.maps.places.Autocomplete(
        //     document.getElementById('search'), {
        //         types: ['establishment', '(cities)'],
        //         componentRestrictions: {country : 'pl'}
        //     }
        // );
        // const places = new google.maps.places.PlacesService(map);
        //
        // autocomplete.addListener('place_changed', onPlaceChanged);

        var input = document.getElementById('search');
        var autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: {country: 'pl'},
            types: ['geocode']
        });
        //autocomplete.bindTo('bounds', map);
        autocomplete.setFields(
            ['geometry']);
        autocomplete.addListener('place_changed', function () {
            let result = autocomplete.getPlace();
            if (result.geometry) {
                Services.map_center = {
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng()
                };
                Services.map.setCenter(Services.map_center);
                Services.service_id = null;
                Services.service = undefined;
                Services.use_map_center = true;
                Services.search();
                Services.use_map_center = false;
                history.pushState({search: input.value}, input.value, '/#services/search=' + encodeURIComponent(input.value));
            } else {
                $('.btn-search').trigger('click');
            }
        });
        $(".btn-search").unbind('click').click(function () {
            history.pushState({search: input.value}, input.value,  '/#services/search=' + encodeURIComponent(input.value));
            PSO.geocoding(input.value, function (response) {
                if (response.results) {
                    let result = response.results[0];
                    Services.map_center = result.geometry.location;
                    Services.map.setCenter(Services.map_center);
                    Services.service_id = null;
                    Services.service = undefined;
                    Services.use_map_center = true;
                    Services.search();
                    Services.use_map_center = false;
                }
            });
        })

        const searchMe = location.hash.match(/#services\/search=(.*)/);
        if (searchMe) {
            setTimeout(() => {
                input.value = decodeURIComponent(searchMe[1]);
                $('.btn-search').trigger('click');
            }, 500)
        }


        //var input = document.getElementById('search');
        // var searchBox = new google.maps.places.SearchBox(input, {
        //     types: ['establishment', '(cities)'],
        //     componentRestrictions: {country : 'pl'}
        // });
        // searchBox.setComponentRestrictions({'country': 'pl'});
        // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        // map.addListener('bounds_changed', function() {
        //     searchBox.setBounds(map.getBounds());
        // });


        return;

    }

    Services.typeahead_source = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: Services.typeahead_source
    });

    Services.$search.typeahead({
        hint: true,
        highlight: true,
        minLength: 2,
    }, {
        name: 'workshops',
        source: Services.typeahead_source
    }).unbind('typeahead:select').bind('typeahead:select', function () {
        let $el = $(this);
        if (typeof Services.search_to_code[$el.typeahead('val')] !== 'undefined') {
            Services.code = Services.search_to_code[$el.typeahead('val')];
            window.location.href = '/#services/code/' + Services.code;
        }
    }).unbind('typeahead:change').bind('typeahead:change', function () {
        let $el = $(this);
        PSO.geocoding($el.typeahead('val'), async function (response) {
            let result = false;
            if (response && typeof response.results[0] !== 'undefined') {
                result = response.results[0];
                Services.map && (Services.map_center = result.geometry.location);
                Services.map && Services.map.setCenter(Services.map_center);
            }
            // If query points to a  a workshop.
            if (typeof Services.search_to_code[$el.typeahead('val')] !== 'undefined') {
                Services.code = Services.search_to_code[$el.typeahead('val')];
                Services.service = PSO.workshops[Services.code];
                Services.service_id = Services.service.id || Services.service.workshopId;

                if (Services.mode !== 'relocation') {
                    if (Services.service.id && window.location.hash.match(/^#services$/)) {
                        window.location.href = '/#services/code/' + (Services.service.code || Services.service.extranetCode);
                    }
                    $('#book-header').removeClass('d-none');
                    if (!PSO.depositModel(Services.deposits).multipleServices()) {

                        if (await _storage.getItem('reservation') === false) {
                            if (!await _storage.getItem('rejection')) {
                                if (Services.service.id) {
                                    $('#book-pinned-button').removeClass('d-none').addClass('720'); ;
                                    $('#book-pinned-button').unbind('click').on('click', () => {
                                            let warnings = checkServiceReservation(Services.service);
                                            let url = '/#calendar/' + Services.service_id;
                                            if (warnings.length) {
                                                return displayWarning(warnings, url)
                                            }
                                            window.location.href = url
                                        }
                                    );
                                } else {
                                    $('.service-offline').removeClass('d-none');
                                    $('.service-offline a').addClass('d-none');
                                    if ($('.telephone').text().replace(/[^+,0-9]+/g, '').split(',')[0]) {
                                        $('#call-pinned-button').removeClass('d-none').attr('href', 'tel://' + $('.telephone').text().replace(/[^+,0-9]+/g, '').split(',')[0]);
                                    }
                                }
                            }
                        } else {
                            if (Services.service.id) {
                                $('#abort-pinned-button').removeClass('d-none');
                            }
                        }

                    }

                    if (Services.code === Services.reservationCode) {
                        Services.service.reservation = true;
                    }

                    if (Services.code in Services.deposits) {
                        Services.service.deposits = PSO.groupDeposit(Services.deposits[Services.code]);
                    }

                } else {
                    $('#relocation-pinned-button').removeClass('d-none');
                }
                if (typeof Services.service.latitude !== 'undefined') {
                    Services.map && (Services.map_center = {
                        lat: Services.service.latitude,
                        lng: Services.service.longitude
                    });
                }
                Services.service.distance = haversine({
                    latitude: Services.user_position.lat || Services.map_center.lat,
                    longitude: Services.user_position.lng || Services.map_center.lng
                }, {
                    latitude: Services.service.latitude,
                    longitude: Services.service.longitude
                });
                if (Services.user_position.lat === undefined) {
                    navigator.geolocation.getCurrentPosition(position => {
                        Services.user_position = {lat: position.coords.latitude, lng: position.coords.longitude};
                        Services.loadUserMarker();
                        Services.service.distance = haversine({
                            latitude: Services.user_position.lat || Services.map_center.lat,
                            longitude: Services.user_position.lng || Services.map_center.lng
                        }, {
                            latitude: Services.service.latitude,
                            longitude: Services.service.longitude
                        });
                        !isNaN(Services.service.distance) &&
                        $('.distance').html(Services.service.distance !== 9999 ? Services.service.distance.toFixed(1).toString().replace('.', ',') + ' km' : '').removeClass('text-grey')
                            .removeClass('d-none');
                        $("#services-container, .main-container").removeClass('no-position');
                        Services.hasGeolocation = true;
                    }, function () {
                        $("#services-container, .main-container").addClass('no-position');
                        Services.hasGeolocation = false;
                    }, {
                        timeout: 2000
                    });
                }
                Services.map && Services.map.setCenter(Services.map_center);
            } else {
                $('#book-pinned-button').addClass('d-none').addClass('line-791'); ;
                Services.$search.typeahead('val', result.formatted_address);
                Services.service_id = null;
                Services.service = undefined;
                Services.use_map_center = true;
                Services.search();
                Services.use_map_center = false;

            }
            if (result) {
                Services.map && Services.map.setCenter(Services.map_center);
                let zoom = boundsToZoom(result.geometry.viewport.northeast, result.geometry.viewport.southwest);
                if (zoom > 15) zoom -= 4;
                Services.map && Services.map.setZoom(zoom);
            }
            Services.search_number++;
            if (Services.service_id === null) {
                // Services.$search.typeahead('val', result.formatted_address);
                // Services.search(result.formatted_address);
            } else {
                Services.render([Services.service])
            }

        });
    });

    if (Services.$search.typeahead('val') === '') {
        Services.getUserPosition();
    }

    // $(".btn-search").click(function() {
    //     Services.$search.trigger('typeahead:change');
    // })

};
Services.search = function () {

    let search_results = [];


    for (let i in PSO.workshops) {
        if (typeof PSO.workshops[i].latitude === 'undefined') {
            if (PSO.workshops[i].coordinates === null) {
                PSO.workshops[i].distance = 9999;
                continue;
            }
            PSO.workshops[i].latitude = PSO.workshops[i].coordinates.latitude;
            PSO.workshops[i].longitude = PSO.workshops[i].coordinates.longitude;
        }
        PSO.workshops[i].distance = haversine({
            latitude: Services.use_map_center ? Services.map_center.lat : (Services.user_position.lat || Services.map_center.lat),
            longitude: Services.use_map_center ? Services.map_center.lng : (Services.user_position.lng || Services.map_center.lng)
        }, {
            latitude: PSO.workshops[i].latitude,
            longitude: PSO.workshops[i].longitude
        });

    }

    let values = [];
    for (let i in PSO.workshops) {
        values.push({key: i, value: PSO.workshops[i]});
    }
    PSO.workshops = {};
    if (Services.reservationCode) {
        const id = values.reduce((carry, item, index) => {
            return ((item.value.extranetCode || item.value.code) === Services.reservationCode) ? index : carry;
        }, false)
        if (id !== false) {
            values[id].value.reservation = true;
            PSO.workshops[values[id].key] = values[id].value;
            delete values[id];
        }
    }
    if (Services.deposits) {
        let depositCodes = Object.keys(Services.deposits);
        depositCodes.map(code => {
            if (code in PSO.workshops) {
                PSO.workshops[code].deposits = PSO.groupDeposit(Services.deposits[code]);
            }
        });
        values.map((item, id) => {
            if (depositCodes.indexOf(item.value.extranetCode || item.value.code) > -1) {
                if (Services.mode !== 'relocation') {
                    PSO.workshops[item.key] = item.value;
                    PSO.workshops[item.key].deposits = PSO.groupDeposit(Services.deposits[item.value.extranetCode || item.value.code]);
                }
                delete values[id];
            }
        })
        if (Services.mode === 'relocation') {
            for (let code in PSO.workshops) {
                if (depositCodes.indexOf(code) !== -1) {
                    delete PSO.workshops[code];
                }
            }
        }
    }
    // if (Object.keys(Services.deposits).length) {
    //     values.forEach(item => {
    //         let code = item.value.extranetCode || item.value.code;
    //         if (code in Services.deposits) {
    //             item.value.deposits = Services.deposits[code];
    //         }
    //     })
    // }
    values.sort(function (a, b) {
        if (a.value.distance < b.value.distance) return -1;
        else if (a.value.distance > b.value.distance) return 1;
        else return 0;
    }).map(item => PSO.workshops[item.key] = item.value);

    for (let i in PSO.workshops) {
        //if (search_results.length < 100) {
        search_results.push(PSO.workshops[i]);
        //}
    }
    Services.render(search_results);
};
Services.render = async function (services) {
    let deposits = Services.deposits;

    /*if (services.length === 0) {
        $('#no-results').show();
    } else {
        $('#no-results').hide();
    }*/

    Services.$container.addClass('animate animate--before-in');
    Services.$container.html('');
    for (let i in Services.markers) Services.markers[i].setMap(null);
    Services.markers = [];
    for (let i in services) {
        let $service = Services.$service_template.clone();
        let $serviceRating = false;
        $service.removeClass('template')
            .attr('id', '')
            .attr('data-code', services[i].code || services[i].extranetCode)
            .attr('data-workshop-id', services[i].workshopId || services[i].id);
        $service.find('.service-online').removeClass('d-flex').addClass('d-none');
        $service.find('.service-offline').removeClass('d-flex').addClass('d-none');
        $service.find('[data-rejection-component-embed]').attr('data-rejection-component-embed', services[i].code || services[i].extranetCode)
        $service.find('.street').html(services[i].street);
        $service.find('.city').html([services[i].zip, services[i].city].join(' '));
        $service.find('.details').attr('href', '/#services/'
            + (Services.mode === 'relocation' ? 'relocation/' : '')
            + `code/${services[i].extranetCode || services[i].code}`);
        // + '?return=' + window.location.pathname);
        if (Services.service && services[i].search_phrase === Services.service.search_phrase) {
            $service.find('.name').addClass('dash-deposit-status text-orange');
            $service.find('.book').addClass('d-none');
        }
        if (services[i].reservation) {
            reservationOnList($service);
        }
        if (services[i].deposits) {
            $service.css('background-color', 'rgba(235, 240, 250,0.5)');
            $service.addClass('no-hover');
            $service.find('.deposited-tire').removeClass('d-none');
            $service.find('.tires').append(`<span class="dash-text-grey d-block font-500 text-uppercase">${PSO.depositStatus(deposits[0].status)}</span>`);
            for (let j in services[i].deposits) {
                $service.find('.tires').append('<span class="dash-text-grey d-block">'
                    + services[i].deposits[j].length + ' x ' + services[i].deposits[j][0].verbalized
                    + '</span>'); // 2 x 255/35 R19 Continental ContiWinter
            }
            $service.find('.relocation-flow').addClass('d-none').removeClass('d-flex');
            if (deposits[0].status === 'relokacja') {
                _storage.getItem('relocations').then(relocations => {
                    if (relocations[0].workshopFrom) {
                        $service.find('.history-workshop-from-name').html(relocations[i].workshopFrom.nameShort);
                        $service.find('.history-workshop-from-city').html(relocations[i].workshopFrom.city);
                        $service.find('.history-workshop-from-street').html(relocations[i].workshopFrom.street);
                    } else {
                        $service.find('.history-workshop-from-name').text('Poza PSO');
                    }
                    if (relocations[0].workshopTo) {
                        $service.find('.history-workshop-to-name').html(relocations[i].workshopTo.nameShort);
                        $service.find('.history-workshop-to-city').html(relocations[i].workshopTo.city);
                        $service.find('.history-workshop-to-street').html(relocations[i].workshopTo.street);
                    } else {
                        $service.find('.history-workshop-to-name').text('Poza PSO');
                    }
                    $service.find('.relocation-flow').removeClass('d-none').addClass('d-flex');
                });
            }
        }
        if (typeof services[i].id !== 'undefined') {
            $service.find('.name').html(services[i].name);
            if (Services.service) {
                $service.find('.telephone').html(`T: <a href="tel://${services[i].prefix}${services[i].phoneNumber}">${services[i].prefix} ${services[i].phoneNumber}</a>`);
                $service.find('.email').html(`E: <a href="mailto:${services[i].emailForClient}">${services[i].emailForClient}</a>`);
            } else {
                $service.find('.telephone').html(`T: ${services[i].prefix} ${services[i].phoneNumber}`);
                $service.find('.email').html(`E: ${services[i].emailForClient}`);
            }
            if (services[i].rating) {
                if (Services.service) {
                    $serviceRating = Services.$service_rating_template.clone();
                    $serviceRating.removeClass('template');
                    $serviceRating.find('.rating-container > div').addClass(rating_class(services[i].rating));
                    $serviceRating.find('.rating-count').text(services[i].ratingCount);
                    $serviceRating.find('.huge-text').text(parseFloat(services[i].rating).toFixed(2));
                    if (services[i].deposits) {
                        $serviceRating.css('background-color', 'rgba(235, 240, 250,0.5)');
                    }
                } else {
                    $service.find('.rating-container > div').addClass(rating_class(services[i].rating));
                    $service.find('.rating-count').text(services[i].ratingCount);
                    $service.find('.rating-container').removeClass('d-none').addClass('d-flex');
                }
            }
            !Services.code && $service.find('.service-online').removeClass('d-none').addClass('d-flex');
            //$service.find('.book-by-telephone').addClass('d-none');
            $service.find('.book').attr('href', `/#calendar/${services[i].id}`);
            if (await _storage.getItem('reservation') !== false) {
                $service.find('.details').removeClass('d-none');
            } else if (PSO.depositModel(Services.deposits).multipleServices()) {
                $service.find('.book').addClass('d-none');
            } else {
                $service.find('.book').attr('href', `/#calendar/${services[i].id}`);
                $service.find('.book').unbind('click').bind('click', function () {
                    const warnings = checkServiceReservation(services[i]);
                    if (warnings.length > 0) {
                        displayWarning(warnings, `/#calendar/${services[i].id}`);
                        return false;
                    }
                    return true;
                }).css('cursor', 'pointer');

                $service.find('.book').removeClass('d-none');
            }
            if (services[i].workdays) {
                $service.find('.open-container').html(normalizeWorkdays(services[i].workdays));
            }
        } else {
            $service.find('.name').html(services[i].nameShort);
            $service.find('.telephone').html('T: ' + services[i].phone);
            if (services[i].emailForClient) {
                $service.find('.email').html(`E: <a href="mailto:${services[i].emailForClient}">${services[i].emailForClient}</a>`);
            } else {
                $service.find('.email').addClass('d-none');
            }
            !Services.code && $service.find('.service-offline').removeClass('d-none').addClass('d-flex');
            $service.find('.book').addClass('d-none');
            $service.find('.distance').addClass('d-none');
        }
        if ((typeof services[i].distance !== 'undefined') && !isNaN(services[i].distance)) {
            $service.find('.distance').html(services[i].distance !== 9999 ? services[i].distance.toFixed(1).toString().replace('.', ',') + ' km' : '').removeClass('text-grey')
                .removeClass('d-none');
        } else {
            $service.find('.distance').addClass('d-none');
        }
        if (PSO.depositModel(Services.deposits).multipleServices()) {

        } else {
            if (Services.mode === 'relocation') {
                $service.find('.book').addClass('d-none');
                $service.find('.details').addClass('d-none');
                if (Services.service_id === null) {
                    $service.find('.relocation').removeClass('d-none');
                    $service.find('.relocation').attr('href', '/#services/relocation/' + (services[i].id ? `id/${services[i].id}` : `workshop-id/${services[i].workshopId}`));
                }
            } else {
                $service.find('.book-by-telephone').removeClass('d-none');

            }
        }


        if (typeof services[i].latitude !== 'undefined' && typeof google !== 'undefined') {
            let marker = new google.maps.Marker({
                position: {lat: services[i].latitude, lng: services[i].longitude}, //new google.maps.LatLng($service[i].latitude, $service[i].longitude),
                title: typeof services[i].id !== 'undefined' ? services[i].name : services[i].nameShort,
                animation: google.maps.Animation.DROP,
                map: Services.map,
                icon: (Services.service_id || services[i].reservation) ? '/build/images/marker.png' : '/build/images/marker_blue.png'
            });
            if ("direction" in QueryParams && Services.user_position.lat && Services.user_position.lng) {
                const direction = QueryParams.direction.split(',');
                const directionsService = new google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer();
                directionsRenderer.setMap(Services.map);
                const request = {
                    origin: new google.maps.LatLng(Services.user_position.lat, Services.user_position.lng),
                    destination: new google.maps.LatLng(direction[0], direction[1]),
                    travelMode: 'DRIVING'
                };
                directionsService.route(request, function (result, status) {
                    if (status == 'OK') {
                        directionsRenderer.setDirections(result);
                    }
                });
            }
            marker.addListener('click', function () {
                Services.$search.typeahead('val', services[i].search_phrase).trigger('typeahead:change');
            });
            Services.markers.push(marker);


        }

        (Services.service_id === null) && $service.unbind('click').click(function (e) {
            Services.$search.typeahead('val', '');

            window.location.href = '/#services/'
                + (Services.mode === 'relocation' ? 'relocation/' : '')
                + `code/${services[i].extranetCode || services[i].code}`;
        }).css('cursor', 'pointer').hover(function () {
            !$(this).hasClass('no-hover') && $(this).css({
                backgroundColor: '#f0f2f5',
                'transition': 'none'
            })
        }, function () {
            !$(this).hasClass('no-hover') && $(this).css({
                backgroundColor: ''
            })
        });

        Services.$container.append($service);
        if ($serviceRating) {
            Services.$container.append($serviceRating);
        }

        document.dispatchEvent(new Event('service-loaded'));


    }

    document.dispatchEvent(new Event('services-loaded'));

    if (Services.reservationCode || PSO.depositModel(Services.deposits).multipleServices()) {
        $('.book').addClass('d-none');
        //$('.book-by-telephone').hide();
    }

    setTimeout(() => Services.$container.addClass('animate animate--in'), 300);

};
Services.init = function () {

    Services.mode = null;
    Services.initGeolocation();
    let code = false;
    if (window.location.href.indexOf('code') !== -1) {
        let code = window.location.hash.match(/code\/([^\/\?]+)/)[1];
        Services.service_id = PSO.workshops[code].id || PSO.workshops[code].workshopId;
        Services.code = code;
        $('#search-input-container').addClass('d-none');
    } else {
        Services.service_id = null;
        Services.service = undefined;
        Services.code = null;
    }

    PSO.getCar(function () {

        reservationOnSingle();
    });

    let deposits = Services.deposits;
    Array.isArray(deposits) && deposits.forEach(deposit => {
        if (deposit.workshop && deposit.workshop.code) {
            if (deposit.status != "relokacja") {
                if (!(deposit.workshop.code in Services.deposits)) {
                    Services.deposits[deposit.workshop.code] = [];
                }
                Services.deposits[deposit.workshop.code].push(deposit);
            }
        }
    })

    _storage.getItem('relocations').then(relocations => {
        (relocations || []).forEach(relocation => {
            if (!(relocation.workshopTo.code in Services.deposits)) {
                Services.deposits[relocation.workshopTo.code] = [];
            }
            Services.deposits[relocation.workshopTo.code].push(relocation.deposit);
        })
    });

    if (window.location.hash.indexOf('relocation') !== -1) {
        Services.mode = 'relocation';
        $('#relocation-header').removeClass('d-none');
    }
    if (window.location.hash.indexOf('book') !== -1) {
        Services.mode = 'book';
    }


    Services.$search = $('#search');
    Services.$container = $('#services-container');
    Services.$service_template = $('#service-template');
    Services.$service_rating_template = $('#service-rating-template');
    Services.$search.keyup(function (e) {
        if (e.which === 13) $(this).trigger('typeahead:change');
    });


    try {
        const map = new GoogleMapsApi(GOOLE_MAP_API_KEY);
        map.load().then(() => {
            Services.initMap();
            Services.initSearch();

        });
    } catch (err) {

    }

    PSO.getReservation().catch(rejection)



};


var Rel = {};
Rel.$relocation_modal = undefined;
Rel.$relocation_deposits_wrapper = undefined;
Rel.$relocation_confirm_modal = undefined;
Rel.openModal = function() {
    Deposits().then((deposits) => {

        if (PSO.depositModel(deposits).multipleServices()) {
            return false;
        }

        Rel.$relocation_deposits_wrapper.html('');
        const grouped = PSO.groupDeposit(deposits);
        for (let i in grouped) {
            Rel.$relocation_deposits_wrapper.append('<span class="dash-text-grey d-block">' + grouped[i].length + ' x ' + grouped[i][0].verbalized + '</span>');
        }
        Rel.$relocation_modal.fadeIn();

        if (deposits[0].workshop) {
            Rel.$relocation_modal.find('.from-name').html(deposits[0].workshop.name || deposits[0].workshop.nameShort);
            Rel.$relocation_modal.find('.from-street').html(deposits[0].workshop.street);
            Rel.$relocation_modal.find('.from-city').html(deposits[0].workshop.city);
        } else {
            Rel.$relocation_modal.find('.from-name').html("Poza PSO");
            Rel.$relocation_modal.find('.from-street').html('');
            Rel.$relocation_modal.find('.from-city').html('');
        }

        Rel.$relocation_modal.find('.to-name').html(Services.service.name || Services.service.nameShort);
        Rel.$relocation_modal.find('.to-street').html(Services.service.street);
        Rel.$relocation_modal.find('.to-city').html(Services.service.city);
        $('.line-solid').height(Rel.$relocation_deposits_wrapper.height() + 20);
        $('.line-dashed').height($('#from-wrapper').height() + 20);
    });
};
Rel.confirm = () => Deposits().then(deposits => {

    if (PSO.depositModel(deposits).multipleServices()) {
        return false;
    }

    let deposits_ids = [];
    for (let i in deposits) {
        deposits_ids.push(deposits[i].depositId);
    }

    PSO.preloader.start();

    API.call('POST', 'deposits-relocate', {
        depositIds: deposits_ids,
        workshopCode: Services.service.code || Services.service.extranetCode
    }, function(response) {
        Rel.$relocation_confirm_modal.fadeIn();
        Rel.$relocation_confirm_modal.find('.confirm-content').hide();
        PSO.preloader.stop();
        if (response === true) {
            Rel.$relocation_confirm_modal.find('#relocation-success').show();
            Rel.$relocation_confirm_modal.find('.btn.btn-primary.button').attr('href',
                '/#services/code/' + (Services.service.code || Services.service.extranetCode)
                ).click(function() {
                Rel.$relocation_confirm_modal.fadeOut();
                Rel.$relocation_modal.fadeOut();
            });
            Rel.$relocation_confirm_modal.find('a.modal-close').click(function(){
                window.location.href = '/#dash';
                return false;
            });
            _event.emmit(_event.ON_DEPOSIT_CHANGE);
            // _storage.resetWithCallbacks()
            // Storage.invalidateDepositRelated();
            /* } else if (response === 'Relocation cannot be carried out due to the lack of free space in the tire storage. Please choose another workshop.') {
                Rel.$relocation_confirm_modal.find('#relocation-error-space').show();
            } else if (response.match(/cannot be relocated due to its status/)) {
                Rel.$relocation_confirm_modal.find('#relocation-error-status').show(); */
        } else {
            Rel.$relocation_confirm_modal.find('#relocation-error-unknown').show();
            Rel.$relocation_confirm_modal.find('#relocation-error-unknown p').text(response);
        }
    }, function(response) {
        Rel.$relocation_confirm_modal.fadeIn();
        Rel.$relocation_confirm_modal.find('.confirm-content').hide();
        // if (response.responseJSON === 'Relocation cannot be carried out due to the lack of free space in the tire storage. Please choose another workshop.') {
        //     Rel.$relocation_confirm_modal.find('#relocation-error-space').show();
        // } else if (response.responseJSON === 'Not all deposits were found') {
        //     Rel.$relocation_confirm_modal.find('#relocation-error-unknown').show();
        //     Rel.$relocation_confirm_modal.find('#relocation-error-unknown p').text("Nie wszystkie depozyty zostały zlokalizowane");
        // } else if (response.responseJSON.match(/cannot be relocated due to its status/)) {
        //     Rel.$relocation_confirm_modal.find('#relocation-error-status').show();
        // } else {
        Rel.$relocation_confirm_modal.find('#relocation-error-unknown').show();
        Rel.$relocation_confirm_modal.find('#relocation-error-unknown p').text(response.responseJSON );
        PSO.preloader.stop();
        // }
    });
});
Rel.init = function() {
    Rel.$relocation_confirm_modal = $('#relocation-confirm-modal');
    Rel.$relocation_modal = $('#relocation-modal');
    Rel.$relocation_deposits_wrapper = $('#relocation-deposits-wrapper');
    $('#relocation-pinned-button').click(Rel.openModal);
    $('#relocation-confirm').click(Rel.confirm);
    document.addEventListener('service-loaded', () => {
        $('a.relocation').off('click').on('click', function(e) {
            e.preventDefault();
            const href = $(this).attr('href');
            const arrHref = href.split('/')
            const id = parseInt(arrHref[arrHref.length - 1]);
            Services.service = Services.getWorkshopById(id , href.indexOf('workshop-id') > -1);
            Rel.openModal();
            return false;
        })
    });
};

// if (document.querySelector('[data-services]')) {

//     const servicesInit = () => {
//         Deposits().then(deposits => {

//             Services.deposits = deposits;
//             Services.getWorkshops(Services.init);
//         });
//     };
//     (async function () {

//         if (!await PSO.isLoggedIn()) {

//             API.asyncGetCall('check-login')
//                 .then(async response => {
//                     if (response.status === true) {

//                         await PSO.setLogin(true);
//                         servicesInit();
//                         window.onhashchange = servicesInit;
//                     } else {

//                         await PSO.setLogin(false);

//                         window.location.href = '/login';
//                     }
//                 })
//                 .catch(async () => {

//                     await PSO.setLogin(false);

//                     window.location.href = '/login';
//                 });
//         } else {

//             servicesInit();
//             window.onhashchange = servicesInit;
//         }
//     })();
// }
// ;
_event.addListener(_event.ON_PAGE_CHANGE, async data => {
    if (data.page !== 'services') return;
    $("#book-pinned-button,#call-pinned-button, #relocation-pinned-button, #abort-pinned-button," +
        "#relocation-header, #book-header").addClass('d-none'); ;

    $("#search-input-container").removeClass('d-none');
    $('#services-container').html('');
    $('#search').val('');
    $('#warning-modal').hide();

    Services.deposits = await _storage.getItem('deposits');
    Services.getWorkshops(Services.init);

    if (location.hash.match(/relocation/)) {
        Rel.init();
    }
    $("[data-toggle=relocation-modal-fade-out]").click(() => $('#relocation-modal').fadeOut());
});


export default Services;
