import * as $ from "jquery";
import {PSO, API} from "./_PSO.js";
import moment from "moment";
import {_event} from "./_events";
import {_storage} from "./_storage";
moment.locale('pl');

let Car = {};
Car.$history_wrapper = undefined;
Car.$history_template = undefined;
Car.$relocation_template = undefined;
Car.history_types = [];
Car.history_types['tirePurchase'] = 'Zakup opony';
Car.history_types['tireChange'] = 'Wymiana opony';
Car.history_types['tireRelocation'] = 'Relokacja';
Car.history_types['tireRepair'] = 'Naprawa opon';
Car.onHistoryChange = response => {
   Car.$history_wrapper = $('#history-wrapper');
   Car.$history_template = $('#history-template');
   Car.$relocation_template = $('#relocation-template');
   $('#history-wrapper').children(":visible").remove();
   for (let i in response) {
      let date = moment(response[i].date);
      let $history;
      if (!response[i].workshop) {
         $history = Car.$relocation_template.clone();
      } else {
         $history = Car.$history_template.clone();
      }
      $history.removeAttr('id');
      $history.removeClass('template');
      $history.find('.history-date').html(date.locale('pl').format('DD MMM YYYY'));
      if (date > moment()) {
         $history.find('.history-bullet').attr('src', '/build/images/Ellipse_orange.png');
         $history.find('.history-status').addClass('text-orange').addClass('text-right').html('Zaplanowane');
      } else {
         $history.find('.history-bullet').attr('src', '/build/images/Ellipse_green.png');
         $history.find('.history-status').addClass('text-green').addClass('text-right').html('Zrealizowane');
      }
      $history.find('.history-type').html(Car.history_types[response[i].type]);

      if (!response[i].workshop) {
         $history.find('.history-workshop-from-name').html(response[i].workshopFrom.nameShort);
         $history.find('.history-workshop-from-city').html(response[i].workshopFrom.city);
         $history.find('.history-workshop-from-street').html(response[i].workshopFrom.street);
         $history.find('.history-workshop-to-name').html(response[i].workshopTo.nameShort);
         $history.find('.history-workshop-to-city').html(response[i].workshopTo.city);
         $history.find('.history-workshop-to-street').html(response[i].workshopTo.street);
      } else {
         $history.find('.history-workshop-name').html(response[i].workshop.nameShort);
         $history.find('.history-workshop-city').html(response[i].workshop.city);
      }

      Car.$history_wrapper.append($history);
   }
}
Car.onCar = car => {
   $('[data-car-title]').html(car.description);
   $('[data-car-reg-nr]').html(car.number);
   $('[data-car-vin]').html(car.vin);
   const grouped = PSO.groupDeposit(car.deposits);
   $('.without-tires').removeClass('d-none');
   $('.with-tires').addClass('d-none');
   if (typeof car.deposits !== 'undefined' && car.deposits.length > 0) {
      $('.without-tires').addClass('d-none');
      $('.with-tires').removeClass('d-none');
   }
   $('#car-deposit-content-wrapper').children().remove();
   for (let i in grouped) {
      $('#car-deposit-content-wrapper').append('<li class="dash-text-grey dash-text-grey-light d-block">' + grouped[i].length + ' x ' + grouped[i][0].verbalized + '</li>'); // 2 x 255/35 R19 Continental ContiWinter

   }
};
Car.onDeposits = deposits => {

   $('#car-workshop-name').html('');
   $('#car-workshop-street').html('');
   $('#car-workshop-city').html('');
   $('#car-workshop-code').html('');

   if (PSO.depositModel(deposits).multipleServices()) {
      $('#car-workshop-status').html("Depozyty znajdują się w różnych serwisach");
      $('#car-workshop-status').removeClass("text-dark-green");
      $('#car-workshop-status').next().removeClass('d-block').hide();
      return;
   }

   if (deposits && deposits[0] && deposits[0].workshop) {
      $('#car-workshop-name').html(deposits[0].workshop.nameShort);
      $('#car-workshop-street').html(deposits[0].workshop.street);
      $('#car-workshop-city').html(deposits[0].workshop.city);
      $('#car-workshop-status').html(PSO.depositStatus(deposits[0].status));
      if (deposits[0].status === 'depozyt') {
         $('.with-deposits img:last').removeClass('d-none');
      } else {
         $('.with-deposits img:first').removeClass('d-none');
         $("#car-workshop-status").addClass('text-orange');
      }
   } else {
      $('#car-workshop-status').html("Poza PSO");
      $('#car-workshop-status').next().removeClass('d-block').hide();
   }
};
Car.init = function() {
   Car.$history_wrapper = $('#history-wrapper');
   Car.$history_template = $('#history-template');
   Car.$relocation_template = $('#relocation-template');
};

_storage.onItemChange('car-history', Car.onHistoryChange);
_storage.onItemChange('car', Car.onCar)
_storage.onItemChange('deposits', Car.onDeposits);

_event.addListener(_event.ON_LOGIN, Car.init);
_event.addListener(_event.ON_PAGE_CHANGE, async data => {
   if (data.page !== 'car') return;
   Car.init();
   Car.$history_wrapper.addClass('animate animate--before-in').removeClass('animate--in');
   PSO.preloader.start();
   Car.onCar(await _storage.getItem('car'));
   Car.onDeposits(await _storage.getItem('deposits'));
   Car.onHistoryChange(await _storage.getItem('car-history'));
   PSO.preloader.stop();
   setTimeout(() => Car.$history_wrapper.addClass('animate animate--in'), 300);
})



